<template>
  <NbTextInput
    v-model="value"
    :name="$t('shippingRulesPage.tips.defineFreightValueToBe')"
    type="money"
    :decimals="2"
  />
</template>

<script>
import NbTextInput from "@/components/input/text/NbTextInput";

export default {
  name: "DefineFreightValueToBe",
  components: {
    NbTextInput,
  },
  computed: {
    value: {
      get() {
        return this.$store.state.shippingRules.actions[0].values?.[0];
      },
      set(val) {
        this.$store.commit("shippingRules/UPDATE_ACTION", { values: [val] });
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <div class="page">
    <NbPageTitle
      class="page-header"
      :title="$t('shippingRulesPage.title')"
      :subTitle="$t('shippingRulesPage.subtitle')"
    />

    <NbTabs class="mb-4" :tabs="tabsOptions" @switchTab="switchTab($event)" />

    <ShippingRuleTab
      @newRule="startNewRuleForm()"
      ref="shippingRuleTab"
      v-show="currentTab === tabs.rules"
      :selectedRule.sync="selectedRule"
    />
    <NewRuleTab
      v-show="currentTab === tabs.newRule"
      @success="onSuccess()"
      @showRules="switchTab(tabs.rules)"
    />
    <EditRuleTab
      v-if="currentTab === tabs.editRule"
      @success="onSuccess()"
      :rule="selectedRule"
    />
  </div>
</template>

<script>
import NbPageTitle from "@/components/pagescomponents/NbPageTitle";
import NbTabs from "@/components/tables/NbTabs";
import ShippingRuleTab from "./components/ShippingRules/ShippingRuleTab.vue";
import NewRuleTab from "./components/ShippingRules/NewRuleTab.vue";
import EditRuleTab from "./components/ShippingRules/EditRuleTab.vue";

export default {
  name: "ShippingRules",
  components: {
    NbPageTitle,
    NbTabs,
    ShippingRuleTab,
    NewRuleTab,
    EditRuleTab,
  },
  data() {
    return {
      selectedRule: {},
      currentTab: "rules",
      tabs: {
        rules: "rules",
        newRule: "newRule",
        editRule: "editRule",
      },
    };
  },
  watch: {
    selectedRule: function (value) {
      if (value?.id) {
        this.switchTab(this.tabs.editRule);
      }
    },
  },
  computed: {
    tabsOptions() {
      const tabs = {
        rules: this.$t("shippingRulesPage.title"),
        newRule: this.$t("shippingRulesPage.newRule"),
        editRule: this.$t("shippingRulesPage.editRule"),
      };
      const options = [
        {
          id: this.tabs.rules,
          label: this.$t("shippingRulesPage.title"),
          current: this.currentTab,
        },
        {
          id: this.tabs.newRule,
          label: this.$t("shippingRulesPage.newRule"),
          current: this.currentTab,
        },
        {
          id: this.tabs.editRule,
          label: this.$t("shippingRulesPage.editRule"),
          current: this.currentTab,
        },
      ];

      return options.filter((item) => {
        if (this.currentTab === tabs.rules) {
          return item.label !== tabs.editRule;
        }
        return this.selectedRule?.id
          ? item.id !== this.tabs.newRule
          : item.id !== this.tabs.editRule;
      });
    },
  },
  methods: {
    startNewRuleForm() {
      this.$store.dispatch("shippingRules/reset");
      this.switchTab(this.tabs.newRule);
    },
    switchTab(tab) {
      if (this.currentTab === this.tabs.editRule) {
        this.selectedRule = {};
        this.$store.dispatch("shippingRules/reset");
      }
      this.currentTab = tab;
    },
    onSuccess() {
      this.switchTab(this.tabs.rules);
      this.$refs.shippingRuleTab?.loadData();
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  margin-bottom: 2rem;
  &-header {
    max-width: 45rem;
  }
}
</style>

<template>
  <div class="attribute">
    <ConditionOverview v-if="condition?.locked" v-bind="overview">
      <div class="actions">
        <NbButton variant="tertiary" icon="edit" @click="onEdit">{{
          $t("edit")
        }}</NbButton>
        <NbButton
          variant="tertiary"
          icon="trash"
          class="ml-2"
          @click="onDelete"
          >{{ $t("delete") }}</NbButton
        >
      </div>
    </ConditionOverview>

    <template v-else>
      <span v-if="loading" class="spinner-border spinner-border-sm"></span>
      <div v-show="!condition?.locked">
        <div
          v-for="channel in availableSalesChannels"
          :key="channel.id"
          class="d-flex align-items-center mt-2"
        >
          <input
            class="nb-checkbox mr-2"
            type="checkbox"
            v-model="channels"
            :value="channel.id"
            :id="'channel-' + channel.id"
          />
          <label :for="'channel-' + channel.id">{{ channel.name }}</label>
        </div>
        <div class="d-flex justify-content-end">
          <NbButton @click="onSave" variant="secondary">{{
            $t("add")
          }}</NbButton>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import SalesChannelManagementService from "@/services/SalesChannelManagementService.js";
import NbButton from "../../../../../../components/buttons/NbButton.vue";
import shippingRulesConditionMixin from "../../../../../../mixins/shipping-rules-condition-mixin";
import ConditionOverview from "../ConditionOverview.vue";

const salesChannelManagementService = new SalesChannelManagementService();

export default {
  name: "Channel",
  mixins: [shippingRulesConditionMixin],
  components: {
    NbButton,
    ConditionOverview,
  },
  data() {
    return {
      loading: false,
      availableSalesChannels: [],
    };
  },
  computed: {
    channels: {
      get() {
        return this.condition.values || [];
      },
      set(val) {
        this.update({ values: val });
      },
    },
    overview() {
      let info = {
        condition: "",
        operator: "",
        values: "",
      };

      if (this.condition?.locked) {
        const values = this.condition.values?.map(Number) || [];
        const channelNames =
          this.availableSalesChannels
            ?.filter((item) => values.includes(item.id))
            ?.map((item) => item.name)
            ?.join(", ") || "";

        info = {
          condition: this.condition?.condition_name,
          operator: this.condition?.operator,
          values: channelNames,
        };
      }

      return info;
    },
  },
  methods: {
    async getAndConfigureSalesChannels() {
      try {
        this.loading = true;
        const { data } = await salesChannelManagementService.getSalesChannels();
        this.availableSalesChannels = data.data;
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.getAndConfigureSalesChannels();
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <div class="attribute">
    <ConditionOverview v-show="condition?.locked" v-bind="overview">
      <div class="actions">
        <NbButton variant="tertiary" icon="edit" @click="onEdit">{{
          $t("edit")
        }}</NbButton>
        <NbButton
          variant="tertiary"
          icon="trash"
          class="ml-2"
          @click="onDelete"
          >{{ $t("delete") }}</NbButton
        >
      </div>
    </ConditionOverview>
    <div v-show="!condition?.locked" class="condition">
      <NbSelectInput
        v-model="operator"
        :options="operators"
        :id="'customer-postal-code-operator' + conditionIndex"
        :name="$t('shippingRulesPage.operator')"
      />

      <NbTextInput
        v-model="values"
        :name="$t('shippingRulesPage.value')"
        :id="'customer-postal-code-value' + conditionIndex"
        :helpText="$t('shippingRulesPage.tips.customerPostalCode')"
      />

      <div class="d-flex justify-content-end">
        <NbButton @click="onSave" variant="secondary" :disabled="!isValid">{{
          $t("add")
        }}</NbButton>
      </div>
    </div>
  </div>
</template>

<script>
import NbTextInput from "@/components/input/text/NbTextInput";
import NbSelectInput from "@/components/input/select/NbSelectInput.vue";
import NbButton from "../../../../../../components/buttons/NbButton.vue";
import ConditionOverview from "../ConditionOverview.vue";
import shippingRulesConditionMixin from "../../../../../../mixins/shipping-rules-condition-mixin";
import { RULE_OPERATORS } from "../../../../../../constants";

export default {
  name: "CustomerPostalCode",
  mixins: [shippingRulesConditionMixin],
  components: {
    NbTextInput,
    NbSelectInput,
    NbButton,
    ConditionOverview,
  },
  computed: {
    operator: {
      get() {
        return this.condition?.operator || "";
      },
      set(val) {
        this.update({ operator: val });
      },
    },
    values: {
      get() {
        return this.condition.values?.[0] || "";
      },
      set(val) {
        this.update({ values: [val] });
      },
    },
    operators() {
      return [
        {
          value: RULE_OPERATORS.IS_ANY_OF,
          text: this.$t("shippingRulesPage.conditionOperators.isAnyOf"),
        },
        {
          value: RULE_OPERATORS.IS_NONE_OF,
          text: this.$t("shippingRulesPage.conditionOperators.isNoneOf"),
        },
        {
          value: RULE_OPERATORS.STARTS_WITH,
          text: this.$t("shippingRulesPage.conditionOperators.startsWith"),
        },
      ];
    },
    isValid() {
      return this.condition?.values?.[0] && this.condition?.operator;
    },
    overview() {
      let info = {
        condition: "",
        operator: "",
        values: "",
      };

      if (this.condition?.locked) {
        info = {
          condition: this.condition?.condition_name,
          operator: this.operators?.find(
            (item) => item.value === this.condition?.operator
          )?.text,
          values: this.condition?.values?.[0] || "",
        };
      }

      return info;
    },
  },
};
</script>

<style lang="scss" scoped>
.attribute {
  .condition {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}
</style>

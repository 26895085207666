export default {
  computed: {
    actionValue: {
      get() {
        return this.$store.state.shippingRules?.actions?.[0]?.values?.[0] || "";
      },
      set(value) {
        this.$store.commit("shippingRules/UPDATE_ACTION", { values: [value] });
      },
    },
  },
};

<template>
  <div class="rule-action">
    <h3 class="heading-3">
      2. {{ $t("shippingRulesPage.newRuleTab.lastStepTitle") }}
    </h3>
    <p class="body-3 description">
      {{ $t("shippingRulesPage.newRuleTab.lastStepDescription") }}
    </p>

    <NbCardBox>
      <h2 class="heading-2">{{ $t("actions") }}</h2>
      <NbSelectInput
        v-model="action"
        required
        :options="actions"
        :name="$t('shippingRulesPage.newRuleTab.defineAnAction')"
        id="rule-action"
        @change="resetActionValues"
        :error="errors['shipping_rules_actions[0].rule_action']"
      />

      <component
        :is="currentAction"
        class="mt-3"
        :errors="errors['shipping_rules_actions[0].values']"
      />

      <div class="d-flex justify-content-end mt-3">
        <NbButton :disabled="loading" @click="submit">
          {{ $t("save") }} {{ $t("rule") }}
        </NbButton>
      </div>
    </NbCardBox>
  </div>
</template>

<script>
import NbCardBox from "@/components/cards/NbCardBox";
import NbSelectInput from "@/components/input/select/NbSelectInput";
import shippingRulesMixin from "@/mixins/shipping-rules-mixin";
import NbButton from "@/components/buttons/NbButton.vue";
import ShippingRulesService from "@/services/ShippingRulesService.js";
import Iconterm from "./Actions/Iconterm.vue";
import Courier from "./Actions/Courier.vue";
import NeverUseCourier from "./Actions/NeverUseCourier.vue";
import DefineFreightValueToBe from "./Actions/DefineFreightValueToBe.vue";
import Package from "./Actions/Package.vue";
import NeverUsePackage from "./Actions/NeverUsePackage.vue";

import { mapGetters } from "vuex";

const shippingRulesService = new ShippingRulesService();
export default {
  name: "RuleAction",
  mixins: [shippingRulesMixin],
  props: {
    sync: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    NbCardBox,
    NbSelectInput,
    NbButton,

    Iconterm,
    Courier,
    NeverUseCourier,
    DefineFreightValueToBe,
    Package,
    NeverUsePackage,
  },
  data() {
    return {
      loading: false,
      currentSelller: null,
    };
  },
  computed: {
    ...mapGetters({
      shippingRuleData: "shippingRules/shippingRulesData",
      shippingRuleId: "shippingRules/shippingRuleId",
    }),
    actions() {
      return this.ruleActionsOptions;
    },
    action: {
      get() {
        return this.$store.state.shippingRules?.actions?.[0]?.name;
      },
      set(value) {
        this.$store.commit("shippingRules/UPDATE_ACTION", { name: value });
      },
    },
    currentAction() {
      return (
        this.ruleActionsOptions?.find((item) => item.value === this.action)
          ?.component || ""
      );
    },
    errors() {
      const errors = this.$store.state.shippingRules?.errors || [];

      return errors?.[0] || [];
    },
  },
  methods: {
    async submit() {
      try {
        this.loading = true;

        if (this.sync) {
          await this.update();
        } else {
          await this.save();
        }

        this.$store.commit("shippingRules/UPDATE_ERRORS", []);
        this.$parent.$emit("success");
      } catch (error) {
        if (error?.response?.data?.messages?.length) {
          this.$store.commit(
            "shippingRules/UPDATE_ERRORS",
            error.response.data.messages
          );
        }
        this.$helpers.toast(`Error saving shipping rule`, "danger", 4000);
      } finally {
        this.loading = false;
      }
    },
    save() {
      return shippingRulesService.setShippingRule(this.shippingRuleData);
    },
    async update() {
      return shippingRulesService.updateShippingRule(
        this.shippingRuleId,
        this.shippingRuleData
      );
    },
    resetActionValues() {
      this.$store.commit("shippingRules/UPDATE_ACTION", {
        values: [],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.rule-action {
  max-width: 38.37rem;
  width: 100%;
}
</style>

<template>
  <div class="edit-rule-tab">
    <RuleConditions class="conditions" sync />
    <RuleAction sync />
  </div>
</template>

<script>
import RuleAction from "./ShippingRuleFormData/RuleAction.vue";
import RuleConditions from "./ShippingRuleFormData/RuleConditions.vue";

export default {
  namespace: "NewRuleTab",
  props: {
    rule: {
      type: Object,
      required: true,
    },
  },
  components: {
    RuleConditions,
    RuleAction,
  },
  created() {
    this.$store.dispatch("shippingRules/setRule", this.rule);
  },
};
</script>

<style lang="scss" scoped>
.edit-rule-tab {
  display: flex;
  gap: 106px;
  .conditions {
    max-width: 21.5rem;
  }
}
</style>

<template>
  <NbSelectInput
    v-model="actionValue"
    :options="iconTerms"
    id="select-action"
    :error="errors"
  />
</template>

<script>
import NbSelectInput from "@/components/input/select/NbSelectInput";
import { ICON_TERMS } from "@/constants";

export default {
  name: "Iconterm",
  props: {
    errors: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    NbSelectInput,
  },
  computed: {
    actionValue: {
      get() {
        return this.$store.state.shippingRules?.actions?.[0]?.values?.[0] || "";
      },
      set(value) {
        this.$store.commit("shippingRules/UPDATE_ACTION", { values: [value] });
      },
    },
    iconTerms() {
      return ICON_TERMS?.map((term) => ({ text: term, value: term }));
    },
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <div class="rule-tab">
    <RuleConditions class="conditions" />
    <div v-if="!hasConditions">
      <ShippingRuleGuide />
    </div>
    <RuleAction v-show="hasConditions" />
  </div>
</template>

<script>
import RuleAction from "./ShippingRuleFormData/RuleAction.vue";
import RuleConditions from "./ShippingRuleFormData/RuleConditions.vue";
import ShippingRuleGuide from "./ShippingRuleGuide.vue";

export default {
  namespace: "NewRuleTab",
  components: {
    RuleConditions,
    RuleAction,
    ShippingRuleGuide,
  },
  computed: {
    hasConditions() {
      return this.$store.getters["shippingRules/hasConditions"];
    },
  },
};
</script>

<style lang="scss" scoped>
.rule-tab {
  display: flex;
  gap: 106px;
  .conditions {
    max-width: 21.5rem;
  }
}
</style>

<template>
  <span v-if="loading" class="spinner-border spinner-border-sm"></span>
  <div v-else>
    <div
      v-for="contract in contracts"
      :key="contract.id"
      class="d-flex align-items-center mt-2"
    >
      <input
        class="nb-checkbox mr-2"
        type="checkbox"
        v-model="couriers"
        :value="contract.id"
        :id="'contract-' + contract.id"
      />
      <label :for="'contract-' + contract.id">{{ contract.name }}</label>
    </div>
  </div>
</template>

<script>
import ContractService from "@/services/ContractService.js";

const contractService = new ContractService();

export default {
  name: "NeverUseCourier",
  data() {
    return {
      loading: false,
      contracts: [],
    };
  },
  computed: {
    couriers: {
      get() {
        return this.$store.state.shippingRules.actions[0].values;
      },
      set(val) {
        this.$store.commit("shippingRules/UPDATE_ACTION", { values: val });
      },
    },
  },
  methods: {
    async loadContracts() {
      try {
        this.loading = true;
        const { data } = await contractService.getContracts();
        this.contracts = data.data;
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.loadContracts();
  },
};
</script>

<style lang="scss" scoped></style>

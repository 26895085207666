<template>
  <span v-if="loading" class="spinner-border spinner-border-sm"></span>
  <div v-else>
    <NbSelectInput
      v-model="actionValue"
      :options="options"
      id="select-action"
      :error="errors"
    />
  </div>
</template>

<script>
import NbSelectInput from "@/components/input/select/NbSelectInput";
import ContractService from "../../../../../../services/ContractService";

const contractService = new ContractService();

export default {
  name: "Courier",
  props: {
    errors: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    NbSelectInput,
  },
  data() {
    return {
      loading: false,
      contracts: [],
    };
  },
  computed: {
    actionValue: {
      get() {
        const val =
          this.$store.state.shippingRules?.actions?.[0]?.values?.[0] || "";
        return Number(val);
      },
      set(value) {
        this.$store.commit("shippingRules/UPDATE_ACTION", { values: [value] });
      },
    },
    options() {
      return this.contracts.map((courier) => ({
        value: courier.id,
        text: courier.name,
      }));
    },
  },
  methods: {
    async loadContracts() {
      try {
        this.loading = true;
        const { data } = await contractService.getContracts();
        this.contracts = data.data;
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.loadContracts();
  },
};
</script>

<style lang="scss" scoped></style>

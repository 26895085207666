<template>
  <div class="rule-conditions">
    <h3 class="heading-3">
      1. {{ $t("shippingRulesPage.newRuleTab.firstStepTitle") }}
    </h3>
    <p class="body-3 description">
      {{ $t("shippingRulesPage.newRuleTab.firstStepDescription") }}
    </p>

    <NbCardBox>
      <h2 class="heading-2">
        {{
          sync
            ? $t("shippingRulesPage.editRule")
            : `${$t("add")} ${$t("shippingRulesPage.newRule")}`
        }}
      </h2>
      <NbTextInput
        v-model="name"
        id="name"
        required
        :name="$t('shippingRulesPage.newRuleTab.ruleName')"
        :placeholder="$t('shippingRulesPage.newRuleTab.ruleName')"
        class="mb-3"
        :error="errors['name']"
      />

      <NbSwitch
        v-model="active"
        :name="$t('shippingRulesPage.fields.is_active')"
        id="rule-active"
        :helpText="$t('shippingRulesPage.tips.is_active')"
      />

      <NbTextInput
        v-model="expirationDate"
        required
        type="date"
        :name="$t('shippingRulesPage.fields.expiration')"
        class="my-3"
        id="expiration"
        :helpText="$t('shippingRulesPage.tips.expiration')"
        :error="errors['expiration']"
      />

      <NbSelectInput
        v-model="priority"
        required
        :options="rulePriorityOptions"
        :name="$t('shippingRulesPage.fields.priority')"
        id="priority"
        :error="errors['priority']"
        :helpText="$t('shippingRulesPage.tips.priority')"
      />

      <div class="conditions">
        <h2 class="heading-2">
          {{ $t("shippingRulesPage.fields.condition") }}
        </h2>
        <div
          class="condition"
          v-for="(condition, index) in conditionsAttributes"
          :key="condition.condition_name"
        >
          <SelectCondition
            v-show="!condition?.locked"
            :options="ruleConditionsOptions"
            :condition-index="index"
            :key="index"
          />

          <keep-alive>
            <component
              :is="getComponent(condition.condition_name)"
              :condition-index="index"
            />
          </keep-alive>
        </div>

        <NbButton
          v-if="showAddNewConditionButton"
          @click="newCondition"
          variant="tertiary"
          icon="plus"
        >
          {{ $t("shippingRulesPage.newRuleTab.newCondition") }}
        </NbButton>
      </div>
    </NbCardBox>
  </div>
</template>

<script>
import NbCardBox from "@/components/cards/NbCardBox";
import NbTextInput from "@/components/input/text/NbTextInput";
import NbSelectInput from "@/components/input/select/NbSelectInput";
import AllShipments from "./Conditions/AllShipments.vue";
import Channel from "./Conditions/Channel.vue";
import ContainsCategory from "./Conditions/ContainsCategory.vue";
import CustomerPostalCode from "./Conditions/CustomerPostalCode.vue";
import DestinationCountry from "./Conditions/DestinationCountry.vue";
import DestinationState from "./Conditions/DestinationState.vue";
import ItemSku from "./Conditions/ItemSku.vue";
import NumberOfItems from "./Conditions/NumberOfItems.vue";
import SellerPrice from "./Conditions/SellerPrice.vue";
import ShipmentWeight from "./Conditions/ShipmentWeight.vue";
import SelectCondition from "./SelectCondition.vue";

import { mapState, mapActions } from "vuex";
import shippingRulesMixin from "../../../../../mixins/shipping-rules-mixin";
import NbButton from "../../../../../components/buttons/NbButton.vue";
import NbSwitch from "@/components/buttons/NbSwitch.vue";

export default {
  name: "RuleConditions",
  mixins: [shippingRulesMixin],
  props: {
    sync: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    NbCardBox,
    NbTextInput,
    NbSelectInput,
    AllShipments,
    Channel,
    ContainsCategory,
    CustomerPostalCode,
    DestinationCountry,
    DestinationState,
    ItemSku,
    NumberOfItems,
    SellerPrice,
    ShipmentWeight,
    SelectCondition,
    NbButton,
    NbSwitch,
  },
  data() {
    return {
      conditionName: "",
    };
  },
  computed: {
    ...mapState({
      shippingRules: (state) => state.shippingRules,
    }),
    name: {
      get() {
        return this.shippingRules.name;
      },
      set(value) {
        this.$store.commit("shippingRules/UPDATE_RULE", { name: value });
      },
    },
    priority: {
      get() {
        return this.shippingRules.priority;
      },
      set(value) {
        this.$store.commit("shippingRules/UPDATE_RULE", { priority: value });
      },
    },
    expirationDate: {
      get() {
        return this.shippingRules.expiration;
      },
      set(value) {
        this.$store.commit("shippingRules/UPDATE_RULE", { expiration: value });
      },
    },
    active: {
      get() {
        return this.shippingRules.active;
      },
      set(value) {
        this.$store.commit("shippingRules/UPDATE_RULE", { active: value });
      },
    },
    conditionsAttributes() {
      return this.shippingRules.conditions_attributes;
    },
    showAddNewConditionButton() {
      return this.conditionsAttributes?.every((item) => item?.locked);
    },
    errors() {
      return this.$store.state.shippingRules?.errors?.[0] || [];
    },
  },
  methods: {
    ...mapActions({
      addCondition: "shippingRules/newCondition",
    }),
    getComponent(conditionName) {
      return (
        this.ruleConditionsOptions.find((item) => item.value === conditionName)
          ?.component || ""
      );
    },
    newCondition() {
      this.addCondition();
    },
  },
};
</script>

<style lang="scss" scoped>
.rule-conditions {
  .conditions {
    border-top: 1px solid var(--gray-20);
    padding: 1.5rem 0 0.5rem 0;
    margin-top: 1.5rem;
    .condition {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      & + .condition {
        margin-top: 1rem;
      }
    }
  }
}
</style>

<template>
  <div class="guide">
    <h3 class="heading-3">
      {{ $t("shippingRulesPage.guide.title") }}
    </h3>

    <p class="body-3">
      {{ $t("shippingRulesPage.guide.subtitle") }}
    </p>

    <NbButton variant="secondary" @click="onClick">{{
      $t("shippingRulesPage.guide.goToShippingRules")
    }}</NbButton>
  </div>
</template>

<script>
import NbButton from "../../../../components/buttons/NbButton.vue";

export default {
  name: "ShippingRuleGuide",
  components: { NbButton },
  methods: {
    onClick() {
      this.$parent.$emit("showRules");
    },
  },
};
</script>

<style lang="scss" scoped>
.guide {
  background-color: var(--gray-05);
  border-radius: 8px;
  padding: 2.5rem;
  margin-top: 5rem;
  text-align: center;
}
</style>

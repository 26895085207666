<template>
  <span v-if="loading" class="spinner-border spinner-border-sm"></span>
  <NbSelectInput
    v-else
    v-model="actionValue"
    :options="boxes"
    id="select-action"
  />
</template>

<script>
import BoxService from "@/services/BoxService.js";
import shippingRulesActionMixin from "../../../../../../mixins/shipping-rules-action-mixin";
import NbSelectInput from "../../../../../../components/input/select/NbSelectInput.vue";

const boxService = new BoxService();

export default {
  name: "Package",
  mixins: [shippingRulesActionMixin],
  components: { NbSelectInput },
  data() {
    return {
      loading: false,
      boxes: [],
    };
  },
  methods: {
    async loadBoxes() {
      try {
        this.loading = true;
        const { data } = await boxService.getBoxes();
        this.boxes = data?.data?.elements?.map((box) => ({
          value: box.id,
          text: box.name,
        }));
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.loadBoxes();
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <div class="attribute">
    <ConditionOverview v-if="condition?.locked" v-bind="overview">
      <div class="actions">
        <NbButton variant="tertiary" icon="edit" @click="onEdit">{{
          $t("edit")
        }}</NbButton>
        <NbButton
          variant="tertiary"
          icon="trash"
          class="ml-2"
          @click="onDelete"
          >{{ $t("delete") }}</NbButton
        >
      </div>
    </ConditionOverview>

    <div v-else class="condition">
      <NbSelectInput
        v-model="operator"
        :options="operators"
        :id="'seller-price-operator' + conditionIndex"
        :name="$t('shippingRulesPage.operator')"
        required
      />

      <NbTextInput
        v-model="values"
        :id="'seller-price-value-' + conditionIndex"
        :name="$t('shippingRulesPage.value')"
        required
        type="money"
        :decimals="2"
        prepend="$"
      />
      <div class="d-flex justify-content-end mt-3">
        <NbButton @click="onSave" variant="secondary" :disabled="!isValid">{{
          $t("add")
        }}</NbButton>
      </div>
    </div>
  </div>
</template>

<script>
import NbButton from "@/components/buttons/NbButton.vue";
import shippingRulesConditionMixin from "@/mixins/shipping-rules-condition-mixin.js";
import ConditionOverview from "../ConditionOverview.vue";
import NbSelectInput from "@/components/input/select/NbSelectInput.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import { RULE_OPERATORS } from "../../../../../../constants";

export default {
  name: "SellerPrice",
  mixins: [shippingRulesConditionMixin],
  components: {
    NbButton,
    ConditionOverview,
    NbSelectInput,
    NbTextInput,
  },
  computed: {
    operators() {
      return [
        {
          value: RULE_OPERATORS.GREATER_THAN,
          text: this.$t("shippingRulesPage.conditionOperators.greaterThan"),
        },
        {
          value: RULE_OPERATORS.GREATER_THAN_OR_EQUAL_TO,
          text: this.$t(
            "shippingRulesPage.conditionOperators.greaterThanOrEqualTo"
          ),
        },
        {
          value: RULE_OPERATORS.LESS_THAN,
          text: this.$t("shippingRulesPage.conditionOperators.lessThan"),
        },
        {
          value: RULE_OPERATORS.LESS_THAN_OR_EQUAL_TO,
          text: this.$t(
            "shippingRulesPage.conditionOperators.lessThanOrEqualTo"
          ),
        },
        {
          value: RULE_OPERATORS.EQUALS_TO,
          text: this.$t("shippingRulesPage.conditionOperators.EqualsTo"),
        },
      ];
    },
    operator: {
      get() {
        return this.condition?.operator || "";
      },
      set(val) {
        this.update({ operator: val });
      },
    },
    values: {
      get() {
        return this.condition.values?.[0] || "";
      },
      set(val) {
        this.update({ values: [val] });
      },
    },
    isValid() {
      return this.condition?.values?.[0] && this.condition?.operator;
    },
    overview() {
      let info = {
        condition: "",
        operator: "",
        values: "",
      };

      if (this.condition?.locked) {
        info = {
          condition: this.condition?.condition_name,
          operator: this.operators.find(
            (item) => item.value === this.condition?.operator
          )?.text,
          values: this.condition?.values?.[0],
        };
      }

      return info;
    },
  },
};
</script>

<style lang="scss" scoped>
.attribute {
  .condition {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}
</style>

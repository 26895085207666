<template>
  <div class="attribute">
    <ConditionOverview v-show="condition?.locked" v-bind="overview">
      <div class="actions">
        <NbButton variant="tertiary" icon="edit" @click="onEdit">{{
          $t("edit")
        }}</NbButton>
        <NbButton
          variant="tertiary"
          icon="trash"
          class="ml-2"
          @click="onDelete"
          >{{ $t("delete") }}</NbButton
        >
      </div>
    </ConditionOverview>

    <div v-show="!condition?.locked">
      <div
        v-for="category in categoriesData"
        :key="category.value"
        class="d-flex align-items-center mt-2"
      >
        <input
          class="nb-checkbox mr-2"
          type="checkbox"
          v-model="categories"
          :value="category.id"
          :id="'category-' + category.id"
        />
        <label :for="'category-' + category.id">{{ category.name }}</label>
      </div>
      <div class="d-flex justify-content-end">
        <NbButton @click="onSave" variant="secondary" :disabled="!isValid">{{
          $t("add")
        }}</NbButton>
      </div>
    </div>
  </div>
</template>

<script>
import NbButton from "../../../../../../components/buttons/NbButton.vue";
import shippingRulesConditionMixin from "../../../../../../mixins/shipping-rules-condition-mixin";
import ConditionOverview from "../ConditionOverview.vue";

import { HS_CODES } from "@/constants.js";

export default {
  name: "ContainsCategory",
  mixins: [shippingRulesConditionMixin],
  components: {
    NbButton,
    ConditionOverview,
  },
  computed: {
    categories: {
      get() {
        return this.condition.values || [];
      },
      set(val) {
        this.update({ values: val });
      },
    },
    categoriesData() {
      return Object.entries(HS_CODES).map(([name, id]) => ({ name, id }));
    },
    isValid() {
      return this.condition?.values?.[0];
    },
    overview() {
      const values = this.condition?.values?.map(Number);
      const categoryNames = this.categoriesData
        .filter((item) => values.includes(item.id))
        ?.map((result) => result.name)
        ?.join(", ");

      return {
        condition: this.condition?.condition_name,
        operator: this.condition?.operator,
        values: categoryNames,
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>

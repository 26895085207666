<template>
  <NbSelectInput
    v-model="conditionName"
    :options="ruleOptions"
    :id="'condition_name-' + conditionIndex"
    :name="$t('shippingRulesPage.newRuleTab.newCondition')"
    required
    @change="resetCondition"
  />
</template>

<script>
import NbSelectInput from "@/components/input/select/NbSelectInput";

export default {
  props: {
    conditionIndex: {
      type: Number,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
  },
  components: {
    NbSelectInput,
  },
  computed: {
    conditionName: {
      get() {
        return this.$store.state.shippingRules.conditions_attributes[
          this.conditionIndex
        ]?.condition_name;
      },
      set(data) {
        this.$store.commit("shippingRules/UPDATE_CONDITION", {
          index: this.conditionIndex,
          value: { condition_name: data },
        });
      },
    },
    conditionsAttributeNames() {
      return this.$store.state.shippingRules.conditions_attributes?.map(
        (item) => item.condition_name
      );
    },
    ruleOptions() {
      return this.options.map((item) => {
        item.disabled = !!this.conditionsAttributeNames.includes(item.value);

        return item;
      });
    },
  },
  methods: {
    resetCondition() {
      this.$store.commit("shippingRules/UPDATE_CONDITION", {
        index: this.conditionIndex,
        value: { operator: null, values: [] },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>

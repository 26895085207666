import HttpService from "./HttpService";

export default class ShippingRulesService {
  constructor() {
    this.httpService = HttpService;
  }

  getSellerShippingRulesStatus(seller_id) {
    return this.httpService.get(
      "/v1/admin_sellers_rules?seller_id=" + seller_id
    );
  }

  getShippingRules(filters = {}) {
    return this.httpService.get("/v1/shipping_rules", {
      params: filters,
    });
  }

  getShippingRule(id) {
    return this.httpService.get("/v1/shipping_rules/" + id);
  }

  // getShippingRuleCondition (id) {
  //   return this.httpService.get('/v1/shipping_rule_condition/' + id);
  // }

  // getShippingRuleAction (id) {
  //   return this.httpService.get('/v1/shipping_rule_action/' + id);
  // }

  setShippingRule(data = {}) {
    return this.httpService.post("/v1/shipping_rules", data);
  }

  // setShippingRuleCondition (data = {}) {
  //   return this.httpService.post('/v1/shipping_rule_condition', data);
  // }

  // setShippingRuleAction (data = {}) {
  //   return this.httpService.post('/v1/shipping_rule_action', data);
  // }

  updateShippingRule(id = null, data = {}) {
    return this.httpService.put("/v1/shipping_rules/" + id, data);
  }

  // updateShippingRuleCondition (id = null, data = {}) {
  //   return this.httpService.put('/v1/shipping_rule_condition/' + id, data);
  // }

  // updateShippingRuleAction (id = null, data = {}) {
  //   return this.httpService.put('/v1/shipping_rule_action/' + id, data);
  // }

  deleteShippingRule(id = null) {
    return this.httpService.delete("/v1/shipping_rules/" + id);
  }

  // deleteShippingRuleCondition (id = null) {
  //   return this.httpService.delete('/v1/shipping_rule_condition/' + id);
  // }

  // deleteShippingRuleAction (id = null) {
  //   return this.httpService.delete('/v1/shipping_rule_action/' + id);
  // }
}

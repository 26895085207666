import { mapState, mapMutations } from "vuex";

export default {
  props: {
    conditionIndex: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapState({
      attributes: (state) => state.shippingRules.conditions_attributes,
    }),
    condition() {
      return this.attributes[this.conditionIndex];
    },
    overview() {
      let info = {
        condition: "",
        operator: "",
        values: "",
      };

      if (this.condition?.locked) {
        info = {
          condition: this.condition?.condition_name,
          operator: this.condition?.operator,
          values: this.condition?.values?.[0],
        };
      }

      return info;
    },
    isValid() {
      return this.condition?.values?.[0] && this.condition?.operator;
    },
  },
  methods: {
    ...mapMutations({
      updateCondition: "shippingRules/UPDATE_CONDITION",
    }),
    update(value) {
      this.updateCondition({
        index: this.conditionIndex,
        value,
      });
    },
    onSave() {
      this.update({ locked: true });
    },
    onEdit() {
      this.update({ locked: false });
    },
    onDelete() {
      this.$store.dispatch(
        "shippingRules/removeCondition",
        this.conditionIndex
      );
    },
  },
};

<template>
  <div class="attribute">
    <ConditionOverview v-if="condition?.locked" v-bind="overview">
      <div class="actions">
        <NbButton variant="tertiary" icon="edit" @click="onEdit">{{
          $t("edit")
        }}</NbButton>
        <NbButton
          variant="tertiary"
          icon="trash"
          class="ml-2"
          @click="onDelete"
          >{{ $t("delete") }}</NbButton
        >
      </div>
    </ConditionOverview>

    <div v-else class="d-flex justify-content-end mt-3">
      <NbButton @click="onSave" variant="secondary">{{ $t("add") }}</NbButton>
    </div>
  </div>
</template>

<script>
import ConditionOverview from "../ConditionOverview.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import shippingRulesConditionMixin from "@/mixins/shipping-rules-condition-mixin.js";

export default {
  name: "AllShipments",
  mixins: [shippingRulesConditionMixin],
  components: {
    NbButton,
    ConditionOverview,
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <div class="shipping-rule-tab">
    <div class="header mb-4">
      <div>
        <h3 class="heading-3">{{ $t("shippingRulesPage.title") }}</h3>
        <p class="body-3 description">
          {{ $t("shippingRulesPage.shippingRuleTab.description") }}
        </p>
      </div>

      <div>
        <h3 class="heading-3">{{ $t("shippingRulesPage.newRule") }}</h3>
        <NbButton icon="plus" @click="$emit('newRule')">{{
          $t("shippingRulesPage.shippingRuleTab.createShippingRule")
        }}</NbButton>
      </div>
    </div>

    <NbTablev2
      ref="table"
      tableOf="shippingRulesPage"
      namespace="shipping_rules"
      :all-fields="tableFields"
      :fields="fields"
      :vistaBtn="false"
      :tabs="false"
      :optionsWidth="180"
      @reloadFields="fields = $event"
    >
      <template #cell(priority)="data">
        <span
          :class="
            data.item.priority >= 4
              ? 'text-info bold'
              : data.item.priority == 3
              ? 'text-warning bold'
              : 'text-danger bold'
          "
        >
          {{ getPriorityDescription(data.item.priority) }}
        </span>
      </template>

      <template #cell(condition)="data">
        <span
          v-for="condition in data.item.conditions_attributes"
          :key="`${condition.id}-cond`"
        >
          {{ condition.condition_text }}
        </span>
      </template>

      <template #cell(action)="data">
        {{ data.item.actions_attributes[0].action_text }}
      </template>

      <template #cell(active)="data">
        <span class="icon">
          <i v-show="data.item.active" class="fas fa-check-square"></i>
          <i v-show="!data.item.active" class="far fa-square"></i>
        </span>
      </template>

      <template #cell(actions)="data">
        <NbButton variant="tertiary" icon="edit" @click="onEdit(data.item)">
          {{ $t("edit") }}
        </NbButton>
        <NbButton
          variant="tertiary"
          icon="trash"
          data-toggle="modal"
          data-target="#modalDeleteShippingRule"
          @click="ruleId = data.item.id"
        >
          {{ $t("delete") }}
        </NbButton>
      </template>
    </NbTablev2>

    <NbModal
      id="modalDeleteShippingRule"
      modalConfig="modal-dialog-centered"
      width="500px"
    >
      <template #header>
        {{
          $t("confirmDelete", {
            val: "",
          })
        }}
      </template>

      <template #footer>
        <div class="d-flex justify-content-end">
          <NbButton variant="secondary" data-dismiss="modal">{{
            $t("cancel")
          }}</NbButton>
          <NbButton
            class="ml-2"
            @click="destroyShippingRule"
            :disabled="loading"
            >{{ $t("delete") }}</NbButton
          >
        </div>
      </template>
    </NbModal>
  </div>
</template>

<script>
import NbButton from "../../../../components/buttons/NbButton.vue";
import NbTablev2 from "../../../../components/tables/NbTablev2.vue";
import shippingRulesMixin from "../../../../mixins/shipping-rules-mixin";
import ShippingRulesService from "../../../../services/ShippingRulesService";
import NbModal from "@/components/modal/NbModal.vue";

const shippingRulesService = new ShippingRulesService();

export default {
  namespace: "ShippingRuleTab",
  components: {
    NbButton,
    NbTablev2,
    NbModal,
  },
  mixins: [shippingRulesMixin],
  props: {
    selectedRule: {
      type: [Object],
      required: true,
    },
  },
  data() {
    return {
      fields: [],
      ruleId: null,
      loading: false,
    };
  },
  computed: {
    tableFields() {
      return [
        {
          key: "priority",
          label: this.$t("shippingRulesPage.fields.priority"),
        },
        { key: "name", label: this.$t("shippingRulesPage.fields.name") },
        {
          key: "condition",
          label: this.$t("shippingRulesPage.fields.condition"),
        },
        { key: "action", label: this.$t("shippingRulesPage.fields.action") },
        { key: "active", label: this.$t("shippingRulesPage.fields.is_active") },
        {
          key: "actions",
          isCustomizable: false,
          label: this.$t("quickActions"),
          class: "right--20 right-sticky",
        },
      ];
    },
  },
  methods: {
    onEdit(item) {
      this.$emit("update:selectedRule", item);
    },
    getPriorityDescription(value) {
      const findText = this.rulePriorityOptions.find(
        (option) => option.value === value
      ).text;
      if (findText) {
        return findText;
      }
      return "-";
    },
    loadData() {
      this.$refs.table?.getData();
    },
    async destroyShippingRule() {
      try {
        this.loading = true;
        await shippingRulesService.deleteShippingRule(this.ruleId);
        this.$helpers.closeModal("modalDeleteShippingRule");
        this.ruleId = null;
        this.loadData();
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.shipping-rule-tab {
  .header {
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 750px) {
      flex-direction: row;
      justify-content: space-between;
      .description {
        max-width: 32.75rem;
      }
    }
  }

  .icon {
    font-size: 18px;
  }
}
</style>

import { CONDITIONS_ATTRIBUTES, RULE_PRIORITY } from "../constants";

export default {
  computed: {
    ruleConditionsOptions() {
      return [
        {
          component: "AllShipments",
          value: CONDITIONS_ATTRIBUTES.ALL_SHIPMENTS,
          text: this.$t("shippingRulesPage.conditions.allShipments"),
          disabled: false,
        },
        {
          component: "ContainsCategory",
          value: CONDITIONS_ATTRIBUTES.CONTAINS_CATEGORY,
          text: this.$t("shippingRulesPage.conditions.containsCategory"),
          disabled: false,
        },
        {
          component: "DestinationCountry",
          value: CONDITIONS_ATTRIBUTES.DESTINATION_COUNTRY,
          text: this.$t("shippingRulesPage.conditions.destinationCountry"),
          disabled: false,
        },
        {
          component: "DestinationState",
          value: CONDITIONS_ATTRIBUTES.DESTINATION_STATE,
          text: this.$t("shippingRulesPage.conditions.destinationState"),
          disabled: false,
        },
        {
          component: "ItemSku",
          value: CONDITIONS_ATTRIBUTES.ITEM_SKU,
          text: this.$t("shippingRulesPage.conditions.itemSku"),
          disabled: false,
        },
        {
          component: "NumberOfItems",
          value: CONDITIONS_ATTRIBUTES.NUMBER_OF_ITEMS,
          text: this.$t("shippingRulesPage.conditions.numberOfItems"),
          disabled: false,
        },
        {
          component: "CustomerPostalCode",
          value: CONDITIONS_ATTRIBUTES.CUSTOMER_POSTAL_CODE,
          text: this.$t("shippingRulesPage.conditions.receiverPostalCode"),
          disabled: false,
        },
        {
          component: "SellerPrice",
          value: CONDITIONS_ATTRIBUTES.SELLER_PRICE,
          text: this.$t("shippingRulesPage.conditions.sellerPrice"),
          disabled: false,
        },
        {
          component: "ShipmentWeight",
          value: CONDITIONS_ATTRIBUTES.SHIPMENT_WEIGHT,
          text: this.$t("shippingRulesPage.conditions.shipmentWeight"),
          disabled: false,
        },
        {
          component: "Channel",
          value: CONDITIONS_ATTRIBUTES.CHANNEL,
          text: this.$t("shippingRulesPage.conditions.channel"),
          disabled: false,
        },
      ];
    },
    ruleActionsOptions() {
      return [
        {
          component: "Iconterm",
          value: "Always use this Incoterm",
          text: this.$t("shippingRulesPage.actions.alwaysUseIncoterm"),
          disabled: false,
        },
        {
          component: "Iconterm",
          value: "Use this Incoterm if no data given",
          text: this.$t("shippingRulesPage.actions.useIncotermIf"),
          disabled: false,
        },
        {
          component: "Courier",
          value: "Always use this Courier",
          text: this.$t("shippingRulesPage.actions.alwaysUseThisCourier"),
          disabled: false,
        },
        {
          component: "Courier",
          value: "Use this Courier if no data given",
          text: this.$t("shippingRulesPage.actions.useThisCourierIf"),
          disabled: false,
        },
        {
          component: "NeverUseCourier",
          value: "Never use Courier(s)",
          text: this.$t("shippingRulesPage.actions.neverUseCourier"),
          disabled: false,
        },
        {
          component: "Package",
          value: "Always use this Package",
          text: this.$t("shippingRulesPage.actions.alwaysUseThisPackage"),
          disabled: false,
        },
        {
          component: "Package",
          value: "Use this Package if no data given",
          text: this.$t("shippingRulesPage.actions.useThisPackageIf"),
          disabled: false,
        },
        {
          component: "NeverUsePackage",
          value: "Never use Package(s)",
          text: this.$t("shippingRulesPage.actions.neverUsePackage"),
          disabled: false,
        },
        {
          component: "DefineFreightValueToBe",
          value: "Define freight value to be",
          text: this.$t("shippingRulesPage.actions.defineFreightValueToBe"),
          disabled: false,
        },
        {
          component: "",
          value: "Define freight value from freight cost",
          text: this.$t("shippingRulesPage.actions.freightValueFromCost"),
          disabled: false,
        },
      ];
    },
    rulePriorityOptions() {
      return [
        {
          value: RULE_PRIORITY.VERY_HIGH,
          text: this.$t("shippingRulesPage.rulePriorityOptions.veryHigh"),
        },
        {
          value: RULE_PRIORITY.HIGH,
          text: this.$t("shippingRulesPage.rulePriorityOptions.high"),
        },
        {
          value: RULE_PRIORITY.MEDIUM,
          text: this.$t("shippingRulesPage.rulePriorityOptions.medium"),
        },
        {
          value: RULE_PRIORITY.LOW,
          text: this.$t("shippingRulesPage.rulePriorityOptions.low"),
        },
        {
          value: RULE_PRIORITY.VERY_LOW,
          text: this.$t("shippingRulesPage.rulePriorityOptions.veryLow"),
        },
      ];
    },
  },
};

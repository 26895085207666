<template>
  <div class="attribute">
    <ConditionOverview v-if="condition?.locked" v-bind="overview">
      <div class="actions">
        <NbButton variant="tertiary" icon="edit" @click="onEdit">{{
          $t("edit")
        }}</NbButton>
        <NbButton
          variant="tertiary"
          icon="trash"
          class="ml-2"
          @click="onDelete"
          >{{ $t("delete") }}</NbButton
        >
      </div>
    </ConditionOverview>

    <template v-else>
      <div v-show="!condition?.locked">
        <div
          v-for="state in statesList"
          :key="state.id"
          class="d-flex align-items-center mt-2"
        >
          <input
            class="nb-checkbox mr-2"
            type="checkbox"
            v-model="states"
            :value="state.id"
            :id="'state-' + state.id"
          />
          <label :for="'state-' + state.id">{{ state.name }}</label>
        </div>
        <div class="d-flex justify-content-end">
          <NbButton @click="onSave" variant="secondary" :disabled="!isValid">{{
            $t("add")
          }}</NbButton>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import NbButton from "@/components/buttons/NbButton.vue";
import shippingRulesConditionMixin from "@/mixins/shipping-rules-condition-mixin.js";
import ConditionOverview from "../ConditionOverview.vue";
import { STATES } from "@/constants.js";

export default {
  name: "DestinationState",
  mixins: [shippingRulesConditionMixin],
  components: {
    NbButton,
    ConditionOverview,
  },
  computed: {
    states: {
      get() {
        return this.condition.values || [];
      },
      set(val) {
        this.update({ values: val });
      },
    },
    statesList() {
      return Object.entries(STATES.BR_STATES)?.map(([name, id]) => ({
        name,
        id,
      }));
    },
    isValid() {
      return this.condition?.values?.[0];
    },
    overview() {
      let info = {
        condition: "",
        operator: "",
        values: "",
      };

      if (this.condition?.locked) {
        const values = this.condition?.values || [];
        const stateNames =
          this.statesList
            .filter((state) => values.includes(state.id))
            ?.map((item) => item.name)
            ?.join(", ") || "";
        info = {
          condition: this.condition?.condition_name,
          operator: this.condition?.operator,
          values: stateNames,
        };
      }

      return info;
    },
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <div class="box">
    <h2 class="heading-2">{{ conditionName }}</h2>
    <div v-show="operator">
      <strong>{{ $t("shippingRulesPage.operator") }}: </strong>
      {{ operator }}
    </div>

    <div v-show="values">
      <strong>{{ $t("shippingRulesPage.value") }}: </strong>
      {{ values }}
    </div>

    <slot></slot>
  </div>
</template>

<script>
import shippingRulesMixin from "../../../../../mixins/shipping-rules-mixin";

export default {
  name: "ConditionOverview",
  mixins: [shippingRulesMixin],
  props: {
    condition: {
      type: String,
      required: true,
    },
    operator: {
      type: [String, null],
      default: null,
    },
    values: {
      type: [String, null],
      default: null,
    },
  },
  computed: {
    conditionName() {
      return (
        this.ruleConditionsOptions?.find(
          (item) => item.value === this.condition
        )?.text || ""
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  padding: 1rem;
  background-color: var(--background);
}
</style>

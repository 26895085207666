<template>
  <span v-if="loading" class="spinner-border spinner-border-sm"></span>
  <div v-else>
    <div
      v-for="box in boxesData"
      :key="box.id"
      class="d-flex align-items-center mt-2"
    >
      <input
        class="nb-checkbox mr-2"
        type="checkbox"
        v-model="boxes"
        :value="box.id"
        :id="'box-' + box.id"
      />
      <label :for="'box-' + box.id">{{ box.name }}</label>
    </div>
  </div>
</template>

<script>
import BoxService from "@/services/BoxService.js";

const boxService = new BoxService();

export default {
  name: "NeverUsePackage",
  data() {
    return {
      loading: false,
      boxesData: [],
    };
  },
  computed: {
    boxes: {
      get() {
        return this.$store.state.shippingRules.actions[0].values;
      },
      set(val) {
        this.$store.commit("shippingRules/UPDATE_ACTION", { values: val });
      },
    },
  },
  methods: {
    async loadBoxes() {
      try {
        this.loading = true;
        const { data } = await boxService.getBoxes();
        this.boxesData = data?.data?.elements;
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.loadBoxes();
  },
};
</script>

<style lang="scss" scoped></style>
